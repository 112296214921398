import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  AppBar,
  Toolbar,
  Box,
  Tooltip,
} from '@mui/material';
import {
  Close as CloseIcon,
  ZoomIn,
  ZoomOut,
  Rotate90DegreesCw as RotateIcon
} from '@mui/icons-material';

const ImageViewer = ({
  image,
  alt = "Image preview",
  previewStyle = {},
  className = "",
  initialScale = 1,
  maxScale = 3,
  minScale = 0.5,
}) => {
  const [open, setOpen] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(initialScale);

  const handleOpen = () => {
    setOpen(true);
    setRotation(0);
    setScale(initialScale);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRotate = () => {
    setRotation((prev) => (prev + 90) % 360);
  };

  const handleZoomIn = () => {
    setScale(prev => Math.min(prev + 0.2, maxScale));
  };

  const handleZoomOut = () => {
    setScale(prev => Math.max(prev - 0.2, minScale));
  };

  return (
    <>
      <img
        src={image}
        alt={alt}
        className={className}
        style={{ cursor: 'pointer', ...previewStyle }}
        onClick={handleOpen}
      />

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
          },
        }}
      >

        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 0,
            marginTop: '64px', // Height of AppBar
            overflow: 'hidden',
          }}
        >
          <img
            src={image}
            alt={alt}
            style={{
              maxHeight: 'calc(100vh - 64px)',
              maxWidth: '100%',
              objectFit: 'contain',
              transform: `rotate(${rotation}deg) scale(${scale})`,
              transition: 'transform 0.3s ease',
            }}
          />
        </DialogContent>

        <AppBar position="fixed" color="default" elevation={0} sx={{ top: 'auto', bottom: 0 }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1, display: 'flex', gap: 1 }}>
              <Tooltip title="Zoom Out">
                <IconButton 
                  onClick={handleZoomOut}
                  color="primary"
                >
                  <ZoomOut />
                </IconButton>
              </Tooltip>
              <Tooltip title="Zoom In">
                <IconButton 
                  onClick={handleZoomIn}
                  color="primary"
                >
                  <ZoomIn />
                </IconButton>
              </Tooltip>
              <Tooltip title="Rotate">
                <IconButton 
                  onClick={handleRotate}
                  color="primary"
                >
                  <RotateIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Close">
                <IconButton
                  edge="end"
                  color="primary"
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </AppBar>
      </Dialog>
    </>
  );
};

export default ImageViewer;